import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './_helper/auth.gaurd';


const  DashboardModule = () => import(`./dashboard/dashboard.module`).then(m => m.DashboardModule);
const  AuthenticationModule = () => import(`./authentication/authentication.module`).then(m => m.AuthenticationModule);
const routes: Routes = [
  {
    path:'',
    redirectTo:'authentication',
    pathMatch:'full',
  },
  {
    path:'dashboard', 
    loadChildren: DashboardModule,
    canActivate: [AuthGuard],
  },
  {
    path:'dynoDashboard', 
    loadChildren: DashboardModule,
    // canActivate: [AuthGuard],
  },
  {
    path:'authentication', 
    loadChildren: AuthenticationModule
  },

  //  otherwise redirect to home
   { path: '**', redirectTo: 'dashboard' }
];
  
@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
