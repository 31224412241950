import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
// import { userModel } from '../dashboard/admin/add-manage-users/add-manager-users.model';
import { User } from '../_model/user.model';

@Injectable({ providedIn: 'root' })
export class AccountService {
    private userSubject: BehaviorSubject<User>;
    public user: Observable<User>;

    token:any;
    userId:any;
    userDetails:any;

    constructor(
        private router: Router,
        private http: HttpClient
    ) {
      console.log(localStorage.getItem('user') || '{}');
        this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user') || '{}'));

        this.user = this.userSubject.asObservable();
        console.log(this.userSubject);  
    }

    public get userValue(): User  {
        return this.userSubject.value;
    }
    
    login(email: string, password: string) {
        return this.http.post<User>(`${environment.apiUrl}/login/`, { email, password })
            .pipe(map(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
                console.log( localStorage.setItem('user', JSON.stringify(user)));
                this.userSubject.next(user);
              console.log(user);
                return user;
            }));
    }

    loginwithGoogle(email: string) {
      return this.http.post<User>(`${environment.apiUrl}/sso_login`, { email})
          .pipe(map(user => {
              // store user details and jwt token in local storage to keep user logged in between page refreshes
              localStorage.setItem('user', JSON.stringify(user));
              this.userSubject.next(user);
              return user;
          }));
  }


    logout() {
        // remove user from local storage and set current user to null
        localStorage.removeItem('user');
        this.userSubject.next(null!);
        this.router.navigate(['authentication/login']);
    }

    // register(user: User) {
    //     return this.http.post(`${environment.apiUrl}/users/register`, user);
    // }

    // getAll() {
    //     return this.http.get<User[]>(`${environment.apiUrl}/users`);
    // }

    getUserById(id: string) {
        return this.http.get(`${environment.apiUrl}/profile/`+id)
        .pipe(map((res:any) => {
            return res;
            
          }))
    }

    getImageById(id: string){
        let profile_id = "profile_" + id;
        return this.http.get(`${environment.apiUrl}/getimage/${profile_id}`).pipe(map((res:any) => {
            return res.data;
        }))
    }
    // update(id:any, params:any) {
    //     return this.http.put(`${environment.apiUrl}/users/${id}`, params)
    //         .pipe(map(x => {
    //             // update stored user if the logged in user updated their own record
    //             if (id == this.userValue.id) {
    //                 // update local storage
    //                 const user = { ...this.userValue, ...params };
    //                 localStorage.setItem('user', JSON.stringify(user));

    //                 // publish updated user to subscribers
    //                 this.userSubject.next(user);
    //             }
    //             return x;
    //         }));
    // }

    // delete(id: string) {
    //     return this.http.delete(`${environment.apiUrl}/users/${id}`)
    //         .pipe(map(x => {
    //             // auto logout if the logged in user deleted their own record
    //             if (id == this.userValue.id) {
    //                 this.logout();
    //             }
    //             return x;
    //         }));
    // }

    onReset(){
      
      this.userDetails = JSON.parse(localStorage.getItem('user') || '');
      this.userId = this.userDetails.user.id;
      this.token = this.userDetails.token;
      localStorage.removeItem('user');
      this.userSubject.next(null!);
      this.router.navigate([`/authentication/reset-password`], {queryParams: {id: this.userId, token: this.token}})
      // this.router.navigate([`/authentication/reset-pasword/:${this.userId}/:${this.token}`])
    }

    changePassword(data: any, id: string){
      var formData = new FormData();
        formData.append('userId', id);
        formData.append('currentPassword', data.currentPassword);
        formData.append('newPassword', data.newPassword);
        formData.append('confirmNewPassword', data.confirmNewPassword);
        return this.http
          .post<User>(
            `${environment.apiUrl}/resetpassword`,
            formData
          )
          .pipe(
            map((res) => {
              return res;
            })
          );
    }

    resetPassword(data: any, id: string, token: string) {
      var formData = new FormData();
      formData.append('id', id);
      formData.append('token', token);
      formData.append('newPassword', data.newPassword);
      formData.append('confirmNewPassword', data.confirmNewPassword);
      return this.http
        .post<User>(
          `${environment.apiUrl}/reset-password`,
          formData
        )
        .pipe(
          map((res) => {
            return res;
          })
        );
    }
    
      forgotPassword(data: any) {
        return this.http
          .post<User>(`${environment.apiUrl}/forgot-password`, data)
          .pipe(
            map((res) => {
              return res;
            })
          );
      }
}