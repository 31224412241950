import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';

import { AccountService } from '../service/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
     type : string;

    constructor(
        private router: Router,
        private accountService: AccountService,
        private route: ActivatedRoute,

    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const user = this.accountService.userValue;
        // this.route.queryParams.subscribe(params=>{
        //     console.log(params.type)
        //     this.type = params.type;
        // });
        // if (this.type === 'byDyno') {
        //     // if (user.token) {
        //     //     // authorised so return true
        //     //     return true;
        //     // }
        //     return true;
        // }
        if (user.token) {
            // authorised so return true
            return true;
        }
         else {
          // Redirect to some other page (e.g., login) if the user is not allowed access.
          this.router.navigate(['/login']);
          return false;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/authentication/login'], { queryParams: { returnUrl: state.url }});
        return false;
    }
}